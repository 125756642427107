@tailwind base;
@tailwind components;
@tailwind utilities;

.ct-app {
  min-height: 100vh;
  background: #f5f5f3;
}

.Toastify__toast--success svg {
  @apply text-green-500;
}

.Toastify__progress-bar--success {
  @apply bg-green-500;
}

.Toastify__toast--error svg {
  @apply text-red-600;
}

.Toastify__progress-bar--error {
  @apply bg-red-600;
}

.Toastify__toast--info svg {
  @apply text-blue-600;
}

.Toastify__progress-bar--info {
  @apply bg-blue-600;
}